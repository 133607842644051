<template>
  <div class="pageRoot">
    <MineSweeperModule />
  </div>
</template>

<script>
import MineSweeperModule from '@/modules/MineSweeper'

export default {
  components: {
    MineSweeperModule,
  },
}
</script>
