import { render, staticRenderFns } from "./LoosePopup.vue?vue&type=template&id=06fd3ccd&scoped=true&"
import script from "./LoosePopup.vue?vue&type=script&lang=js&"
export * from "./LoosePopup.vue?vue&type=script&lang=js&"
import style0 from "./LoosePopup.vue?vue&type=style&index=0&id=06fd3ccd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06fd3ccd",
  null
  
)

export default component.exports